<template>
  <div style="height: inherit">
    <div
        class="body-content-overlay"
    />

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
          v-if="!activeMessageGroup"
          class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
              icon="MessageSquareIcon"
              size="56"
          />
        </div>
        <h4
            class="sidebar-toggle start-chat-text"
            @click="startConversation"
        >
          Új beszélgetés indítása
        </h4>
      </div>

      <!-- Chat Content -->
      <div
          v-else
          class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">
              <h6 class="mb-0">
                {{ activeMessageGroup.messageGroup.chat_partner }}
              </h6>
            </div>
            <div
                v-if="activeMessageGroup.messageGroup.EntityPublicUrl && activeMessageGroup.messageGroup.EntityPublicUrl.length"
                class="d-flex align-items-center flex-end"
            >
              <b-tooltip
                  triggers="hover"
                  title="Ugrás a tartalmi oldalra"
                  :target="`jump-button-${activeMessageGroup.messageGroup.id}`"
                  :delay="{ show: 100, hide: 50 }"
              />
              <a
                  :href="activeMessageGroup.messageGroup.EntityPublicUrl"
                  target="_blank"
              >
                <b-button
                    :id="`jump-button-${activeMessageGroup.messageGroup.id}`"
                    variant="primary"
                    size="sm"
                >
                  <feather-icon
                      icon="ExternalLinkIcon"
                  />
                </b-button>
              </a>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
            v-if="activeMessageGroup.chatLog"
            id="chatLog"
            ref="chatLog"
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
        >
          <div class="chats">
            <b-overlay
                :show="chatLogLoading"
                spinner-variant="primary"
                spinner-type="grow"
                rounded="sm"
                opacity="0.95"
            >
              <div
                  v-for="(msg, index) in activeMessageGroup.chatLog"
                  :key="msg.sender.id+String(index)"
                  class="chat"
                  :class="{'chat-left': msg.isAdmin}"
              >
                <div class="chat-avatar">
                  <b-avatar
                      size="36"
                      class="avatar-border-2 box-shadow-1"
                      variant="primary"
                      :text="msg.isAdmin ? 'A' : msg.sender.name.slice(0,2).toUpperCase()"
                  />
                </div>
                <div class="chat-body" >
                  <div
                      class="chat-content"
                      :class="{'border': !msg.Seen, 'border-warning': !msg.Seen, }"
                  >
                    <p v-html="msg.message"/><br>
                    <p class="font-small-1">{{ formatDate(msg.created_at) }}</p>
                  </div>
                </div>
              </div>
            </b-overlay>
          </div>
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
            class="chat-app-form"
            @submit.prevent="sendMessage"
        >
          <b-input-group class="input-group-merge form-send-message mr-1 mt-1">
            <editor
                v-model="chatInputMessage"
                placeholder="Válasz üzenet ..."
            />
          </b-input-group>
          <b-button
              variant="primary"
              type="submit"
          >
            Küldés
          </b-button>
        </b-form>
      </div>
    </section>

    <portal to="content-renderer-sidebar-left">
      <div class="sidebar-left">
        <div class="sidebar">
          <!-- Sidebar Content -->
          <div
              class="sidebar-content"
              :class="{'show': true}"
          >
            <!-- Header + Search ...-->
            <div class="chat-fixed-search">
              <div class="d-flex align-items-center w-100">
                <!-- Search -->
                <b-input-group class="input-group-merge w-100 round">
                  <b-input-group-prepend is-text>
                    <feather-icon
                        icon="SearchIcon"
                        class="text-muted"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                      v-model="searchQuery"
                      placeholder="Keresés ..."
                      @input="search"
                  />
                </b-input-group>
              </div>
            </div>

            <!-- ScrollArea: Chat & Contacts -->
            <vue-perfect-scrollbar
                :settings="perfectScrollbarSettings"
                class="chat-user-list-wrapper list-group scroll-area"
            >

              <!-- Chats Title -->
              <h4 class="chat-list-title mb-1">
                Beszélgetések
              </h4>

              <!-- Chats -->
              <b-overlay
                  :show="loading"
                  spinner-variant="primary"
                  spinner-type="grow"
                  rounded="sm"
              >
                <ul class="chat-users-list chat-list media-list">
                  <li
                      v-if="!messageGroups.length && !loading"
                  >
                    A keresés nem járt eredménnyel!
                  </li>
                  <li
                      v-for="messageGroup in messageGroups"
                      :key="messageGroup.id"
                      :class="{'active': activeMessageGroupId === messageGroup.id}"
                      @click="openGroup(messageGroup.id)"
                  >
                    <div class="chat-info flex-grow-1">
                      <b-badge
                          v-if="messageGroup.UnseenMsgCnt"
                          pill
                          variant="primary"
                          class="float-right"
                      >
                        {{ messageGroup.UnseenMsgCnt }}
                      </b-badge>
                      <h5 class="mb-0 font-weight-bold">
                        {{
                          messageGroup.generatedSubject
                        }}
                      </h5>
                      <p class="card-text text-truncate" style="max-height: 50px;"
                         v-html="'<div>' + messageGroup.LastMessage.message + '</div>'"/>
                      <div class="chat-meta text-nowrap">
                        <small class="float-right">{{ messageGroup.chat_partner }}</small><br />
                        <small class="float-right mb-25 chat-time">{{ formatDate(messageGroup.LastMessage.created_at) }}</small>
                      </div>
                    </div>
                  </li>
                </ul>
              </b-overlay>
            </vue-perfect-scrollbar>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import {
  BForm, BInputGroupPrepend, BInputGroup, BFormInput, BButton, BOverlay, BBadge, BAvatar, BTooltip,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {mapGetters} from "vuex";
import editor from '@/views/pages/_components/editor.vue'

export default {
  components: {
    BForm,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BButton,
    BOverlay,
    BBadge,
    BAvatar,
    BTooltip,
    VuePerfectScrollbar,
    editor,
  },
  data() {
    return {
      loading: false,
      chatLogLoading: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      messageGroups: [],
      activeMessageGroupId: false,
      activeMessageGroup: false,
      searchQuery: '',
      modelType: '',
      modelId: '',
      id: '',
      chatInputMessage: '',
      users: [],
      entityTypes: [],
    }
  },
  computed: {
    // eslint-disable-next-line no-undef
    ...mapGetters([
      'userID',
    ]),
  },
  created() {
    this.loading = true
    if(typeof this.$route.query.model_type !== "undefined"){
      this.modelType = this.$route.query.model_type
    }
    if(typeof this.$route.query.model_id !== "undefined"){
      this.modelId = this.$route.query.model_id
    }
    if(typeof this.$route.query.id !== "undefined"){
      this.id = this.$route.query.id
    }

    this.$store.dispatch('fetchUsersForSelect').then(response => {
      this.users = response

      this.$store.dispatch('fetchMessageEntityTypes').then(response => {
        this.entityTypes = response.data.values
        this.initGroups()
      })
    })
  },
  methods: {
    initGroups() {
      this.loading = true
      this.activeMessageGroup = false
      this.activeMessageGroupId = {}
      this.$store.dispatch('fetchMessageGroups', {
        query: this.searchQuery,
        modelType: this.modelType,
        modelId: this.modelId,
        id: this.id
      })
          .then(response => {
            this.messageGroups = response.data.entities

            let id = ''

            Object.keys(this.messageGroups).forEach(key => {
              let chatPartnerName = '-'
              let modelTypeName = '-'

              Object.keys(this.users).forEach(uKey => {
                if (this.messageGroups[key].chat_partner_id === this.users[uKey].value) {
                  chatPartnerName = this.users[uKey].name+" ("+this.users[uKey].username+")"
                }
              })

              Object.keys(this.entityTypes).forEach(eKey => {
                if (this.messageGroups[key].MessageGroupType === this.entityTypes[eKey].value) {
                  modelTypeName = this.entityTypes[eKey].title
                }
              })

              if(
                  typeof this.$route.query.model_type !== "undefined" &&
                  this.$route.query.model_type === this.messageGroups[key].MessageGroupType &&
                  typeof this.$route.query.model_id !== "undefined" &&
                  parseInt(this.$route.query.model_id) === parseInt(this.messageGroups[key].model_id)
              ){
                id = this.messageGroups[key].id
              }

              // set data
              this.messageGroups[key].chat_partner = chatPartnerName
              this.messageGroups[key].MessageGroupTypeName = modelTypeName
            })

            if(typeof this.$route.query.id !== "undefined"){
              id = this.$route.query.id
            }

            if(id !== ''){
              this.openGroup(id)
            }
          }).finally(() => {
        this.loading = false
      })
    },
    startConversation() {
      this.$router.push({name: 'add-message'})
    },
    search() {
      this.initGroups()
    },
    openGroup(messageGroupID) {
      this.chatLogLoading = true

      // check if msg group has unseen messages
      let msgGroup = []
      Object.keys(this.messageGroups).forEach(key => {
        if (this.messageGroups[key].id === messageGroupID) {
          msgGroup = this.messageGroups[key]
        }
      })

      this.$store.dispatch('fetchMessageGroup', {messageGroupID, limit: 1000})
          .then(response => {

            this.activeMessageGroupId = messageGroupID
            this.activeMessageGroup = {
              ...response.data.messages.data, ...{
                messageGroup: msgGroup,
                chatLog: this.formatMessagesForChatLog(response.data.messages.data)
              }
            }

            this.scrollToBottomInChatLog(true)

            // ha kell átállítjuk, amúgy skip
            if (!msgGroup.unseenMsgCnt > 0) {
              this.setSeenMessageGroup(messageGroupID)
              msgGroup.unseenMsgCnt = 0
            }

            Object.keys(this.messageGroups).forEach(key => {
              if(this.messageGroups[key].id === messageGroupID){
                this.messageGroups[key].UnseenMsgCnt = 0
              }
            })

          }).catch(() => {
        this.chatLogLoading = false
      }).finally(() => {
        this.$root.$emit('openChatGroup')
      })
    },
    formatMessagesForChatLog(messages = []) {
      if (messages.length) {
        let ret = []

        Object.keys(messages).forEach(key => {
          let isAdmin = true

          if (messages[key].recipient_id == null) {
            isAdmin = false
          }

          ret.push({...messages[key], ...{isAdmin}})
        })

        return ret
      }

      return []
    },
    sendMessage() {
      if (this.chatInputMessage.length) {
        this.$store.dispatch('replyToMessage', {
          messageGroupId: this.activeMessageGroupId,
          message: this.chatInputMessage
        })
            .then(() => {
              this.openGroup(this.activeMessageGroupId)
              this.$helpers.showSuccessToast()
            }).finally(() => {
          this.chatInputMessage = ''
        })
      }
    },
    setSeenMessageGroup(messageGroupID) {
      this.$store.dispatch('seeMessage', messageGroupID).then(() => {
        this.$store.dispatch('initCurrentUser')
      })
    },
    formatDate(beDate) {
      return this.$formatters.formatLaravelTimestampToDateTime(beDate)
    },
    scrollToBottomInChatLog(disableLoading = false) {
      setTimeout(() => {
        let scrollEl = this.$refs.chatLog.$el ? this.$refs.chatLog.$el : this.$refs.chatLog
        scrollEl.scrollTop = scrollEl.scrollHeight

        if (disableLoading) {
          setTimeout(() => {
            this.chatLogLoading = false
          }, 300)
        }
      }, 10)
    },
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
<style>
.chat-content *, .chat-info .card-text *{
  font-size: 16px !important;
  font-weight: 400;
}
</style>
